/**
 * @typedef {Object} App
 * @property {string} id - The unique identifier for the app.
 * @property {string} name - The name of the app.
 * @property {string} msReviewId - The Microsoft Store review id
 */

/** @type {App[]} */
export const apps = [
  {
    id: "hub-mail",
    name: "<b>Mail</b> Hub",
    msReviewId: "9N2X3BPM4X3S",
  },
  {
    id: "hub-messaging",
    name: "<b>Multi</b> Messenger",
    msReviewId: "9PCDP529JZNF",
  },
  {
    id: "hub-stream",
    name: "<b>Stream</b> Hub",
    msReviewId: "9NP540JKZ83B"
  },
  {
    id: "multi-mail",
    name: "<b>Multi</b> Mail",
    msReviewId: "9PBWJ8LF01B7 "
  }  
];

/**
 * @typedef {Object} Service
 * @property {string} name - The name of the service.
 * @property {string} loginUrl - The URL for logging into the service.
 * @property {string} iconUrl - The URL for the service's icon.
 * @property {string} desc - The description of the service.
 * @property {string} activeColor - The active color associated with the service.
 */

/**
 * Gets the icon URL for a given service name.
 * @param {string} name - The name of the service.
 * @returns {string} The icon URL of the service.
 */
export function getServiceIcon(name: string): string {
  const baseUrl = "https://phpstack-941640-4651445.cloudwaysapps.com/";
  const formattedName = name.toLowerCase().replace(/[\s.+]/g, "-");
  const url = `${baseUrl}${encodeURIComponent(formattedName)}.png`;
  return url;
}

/**
 * Extracts active objects from nested arrays.
 * @param {Array<Array<{ isActive: boolean }>>} nestedArrays - The nested arrays to extract from.
 * @returns {Array<Object>} The active objects.
 */
export function getActiveObjects(nestedArrays) {
  // Initialize an array to hold active objects
  const activeObjects = [];

  // Iterate through each sub-array
  for (const subArray of nestedArrays) {
    // Filter objects that have `isActive` property set to true
    const activeInSubArray = subArray.filter((obj) => obj.isActive === true);

    // Add those active objects to the final list
    activeObjects.push(...activeInSubArray);
  }

  return activeObjects;
}

/**
 * Extracts an ID from a given path.
 * @param {string} path - The path to extract the ID from.
 * @returns {string|null} The extracted ID or null if not found.
 */
export function extractIdFromPath(path) {
  const parts = path.split("/");
  return parts[2] || null;
}

/**
 * Gets the bounding client rect of a React ref.
 * @param {Object} reactRef - The React ref to get the bounding rect of.
 * @returns {Object} An object containing the width, height, x, and y of the bounding rect.
 */
export function getBoundingByReactRef(reactRef) {
  const bounds = reactRef.current.getBoundingClientRect();

  return {
    width: parseInt(bounds.width, 10),
    height: parseInt(bounds.height, 10),
    x: parseInt(bounds.x, 10),
    y: parseInt(bounds.y, 10),
  };
}

/**
 * Creates a debounced version of a function.
 * @param {Function} fn - The function to debounce.
 * @param {number} delay - The delay in milliseconds.
 * @returns {Function} The debounced function.
 */
export function debounce(fn, delay) {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

/**
 * Finds an object by ID in nested arrays.
 * @param {Array<Array<{ id: string }>>} arrays - The nested arrays to search.
 * @param {string} id - The ID to search for.
 * @returns {Object|null} The found object or null if not found.
 */
export function findObjectById(arrays, id) {
  // Iterate through each inner array
  for (const subArray of arrays) {
    // Iterate through each object in the inner array
    for (const obj of subArray) {
      // If the object's id matches the target id, return the object
      if (obj.id === id) {
        return obj;
      }
    }
  }

  // Return null if no matching object is found
  return null;
}

/**
 * Generates a unique ID.
 * @returns {string} The generated unique ID.
 */
export function generateUniqueId() {
  const timestamp = new Date().getTime();
  const random = Math.floor(Math.random() * 10000);
  return `${timestamp}_${random}`;
}

/**
 * Gets login url to service
 * @param {string} name - The name of the service.
 * @returns {string} The formatted login url.
 */
export function getLoginUrlByServiceName(name) {
  return `https://phpstack-941640-4651453.cloudwaysapps.com/${name
    .toLowerCase()
    .replace(/[\s.]/g, "-")}`;
}

/**
 * Gets app information by app ID.
 * @param {string} id - The ID of the app.
 * @returns {App|null} The app object with the matching ID, or null if not found.
 */
export const getAppInfoById = (id) =>
  apps.filter((app) => app.id === id)[0] || null;

/**
 * Strips HTML tags from a given string.
 *
 * @param {string} str - The string containing HTML tags.
 * @returns {string} - The string without HTML tags.
 */
export const stripHtmlTags = (str) => {
  var tempDiv = document.createElement("div");
  tempDiv.innerHTML = str;
  return tempDiv.textContent || tempDiv.innerText || "";
};

/**
 * Get the subdomain from the current location's hostname.
 *
 * @returns {string|null} The subdomain if present, "hub-mail" if on localhost, or null if no subdomain is found.
 */
export const getSubdomainByLocation = () => {
  const hostname = window.location.hostname;

  function isIpAddress(hostname) {
    const ipPattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return ipPattern.test(hostname);
  }  

  if (hostname === "localhost" || isIpAddress(hostname)) {
    return "multi-mail";
  }

  const parts = hostname.split(".");
  if (parts.length <= 2) {
    return null;
  }

  return parts.slice(0, parts.length - 2).join(".");
};
